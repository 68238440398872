export class Application {
  id: string;

  name: string;

  description: string;

  details: string;

  icon: string;

  gallery: string[];

  totalReviews: number;

  rate: number;

  totalInstall: number;

  installed: boolean;

  type: ApplicationType;

  comingSoon: boolean;

}


export enum ApplicationType {
  SALES_POP = 'SALES_POP',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  SNAPCHAT_PIXEL = 'SNAPCHAT_PIXEL',
  TIKTOK_PIXEL = 'TIKTOK_PIXEL',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
  COUNTDOWN = 'COUNTDOWN',
  GOOGLE_SHEET = 'GOOGLE_SHEET',
  NETWORK_ACCESS = 'NETWORK_ACCESS',
  FACEBOOK_CONVERSION_API = 'FACEBOOK_CONVERSION_API',
  MAGENTO = 'MAGENTO',
  WHATSAPP = 'WHATSAPP',
  WHATSAPP_CONFIRM = 'WHATSAPP_CONFIRM',
  INCOGNITO = 'INCOGNITO',
  ABANDONED_CART = 'ABANDONED_CART',
  ABANDONED_PAYMENT = 'ABANDONED_PAYMENT',
  SHIPSEN = 'SHIPSEN',
  X10LEAD = 'X10LEAD',
  OZONE = 'OZONE',
  CATHEDIS = 'CATHEDIS',
  CLOAKYAN= 'CLOAKYAN'
}

export enum ConversionType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT'
}

export enum PixelType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS'
}

