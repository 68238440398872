export class PlanDiscount {
  id?: string;
  discountCode: string;
  title: string;
  expirationDate?: Date;
  fixedAmount: number;
  percent: number;
  maxUse?: number;
  uses: number;
  monthlyDuration?: number;
  yearlyDuration?: number;
  enabled: boolean;
  createdDate: Date;
  lastModifiedDate: Date;
  createdBy: string;
  lastModifiedBy: string;
  affiliateId?: string;
  plans?: string[];
  affiliatePercent?: number;
}
